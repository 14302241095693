// src/pages/Contact.js

import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-lg p-8 mb-8">
          <h1 className="text-4xl font-bold">Contacto</h1>
          <p className="text-lg mt-4">
            Si tienes alguna pregunta o comentario, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte.
          </p>
          <div className="mt-6">
            <h2 className="text-2xl font-semibold">Dirección</h2>
            <p className="text-lg">123 Main Street, West Hazleton, PA, 12345</p>
            <h2 className="text-2xl font-semibold mt-4">Correo Electrónico</h2>
            <p className="text-lg">contacto@parroquia.co</p>
            <h2 className="text-2xl font-semibold mt-4">Teléfono</h2>
            <p className="text-lg">(123) 456-7890</p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Contact;
