// src/components/Navbar.js

import React, { useState, useCallback } from 'react';
import { Search, Menu, Church, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaTwitter, FaEnvelope } from 'react-icons/fa';

// Funciones de plataformas para compartir URLs
const platforms = {
  whatsapp: (text, url) => `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`,
  twitter: (text, url) => `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`,
  email: (subject, body) => `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
};

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleShareMenu = () => {
    setIsShareMenuOpen(!isShareMenuOpen);
  };

  const closeShareMenu = () => {
    setIsShareMenuOpen(false);
  };

  const shareCurrentUrl = useCallback((platform) => {
    const currentUrl = window.location.href;
    const shareText = `Visita este enlace de Diosidencias:`;

    // Verifica si la API de Web Share está disponible
    if (navigator.share) {
      navigator.share({
        title: 'Diosidencias',
        text: shareText,
        url: currentUrl,
      }).then(() => {
        console.log('Contenido compartido con éxito');
      }).catch((error) => {
        console.error('Error al compartir:', error);
      });
    } else {
      // Si no está disponible, usa la URL de la plataforma
      const sharePlatformUrl = platforms[platform](shareText, currentUrl);
      window.open(sharePlatformUrl, '_blank');
    }
    // Cerrar el menú después de compartir
    closeShareMenu();
  }, []);

  return (
    <header className="bg-white shadow-md relative">
      <div className="container mx-auto px-4 py-3 flex items-center justify-between">
        <div className="flex items-center">
          <Church className="text-blue-500 mr-2" size={24} aria-hidden="true" />
          <span className="font-bold text-xl">Diosidencias</span>
        </div>

        {/* Desktop Navigation */}
        {/* <nav className="hidden lg:flex flex-grow justify-center space-x-6">
          <Link to="/" className="text-gray-600 hover:text-blue-500">Inicio</Link>
          <Link to="/about" className="text-gray-600 hover:text-blue-500">Sobre Nosotros</Link>
          <Link to="/privacy-policy" className="text-gray-600 hover:text-blue-500">Política de Privacidad</Link>
          <Link to="/terms-of-service" className="text-gray-600 hover:text-blue-500">Términos de Servicio</Link>
          <Link to="/contact" className="text-gray-600 hover:text-blue-500">Contacto</Link>
        </nav> */}

        {/* Share and Mobile Menu Button */}
        <div className="flex items-center space-x-4">
          <button
            className="text-gray-500 focus:outline-none"
            onClick={toggleShareMenu}
            aria-label="Compartir URL actual"
          >
            <Share2 size={24} aria-hidden="true" />
          </button>
          {/* <button
            className="lg:hidden text-gray-500 focus:outline-none"
            onClick={toggleMobileMenu}
            aria-label="Abrir menú de navegación"
          >
            <Menu size={24} aria-hidden="true" />
          </button> */}
        </div>
      </div>

      {/* Mobile Menu */}
      {/* {isMobileMenuOpen && (
        <div className="lg:hidden bg-white shadow-md absolute top-14 left-0 w-full z-50">
          <nav className="flex flex-col space-y-4 p-4">
            <Link to="/" className="text-gray-600 hover:text-blue-500">Inicio</Link>
            <Link to="/about" className="text-gray-600 hover:text-blue-500">Sobre Nosotros</Link>
            <Link to="/privacy-policy" className="text-gray-600 hover:text-blue-500">Política de Privacidad</Link>
            <Link to="/terms-of-service" className="text-gray-600 hover:text-blue-500">Términos de Servicio</Link>
            <Link to="/contact" className="text-gray-600 hover:text-blue-500">Contacto</Link>
          </nav>
        </div>
      )} */}

      {/* Share Menu */}
      {isShareMenuOpen && (
        <div className="absolute top-14 right-4 bg-white shadow-md rounded-lg p-4 z-50">
          {Object.keys(platforms).map(platform => (
            <button
              key={platform}
              onClick={() => shareCurrentUrl(platform)}
              className="flex items-center justify-start p-2 hover:bg-gray-100 text-gray-800 w-full text-left transition-colors duration-200"
              aria-label={`Compartir en ${platform}`}
            >
              {platform === 'whatsapp' && (
                <>
                  <FaWhatsapp size={20} className="mr-2" aria-hidden="true" />
                  WhatsApp
                </>
              )}
              {platform === 'twitter' && (
                <>
                  <FaTwitter size={20} className="mr-2" aria-hidden="true" />
                  Twitter
                </>
              )}
              {platform === 'email' && (
                <>
                  <FaEnvelope size={20} className="mr-2" aria-hidden="true" />
                  Correo
                </>
              )}
            </button>
          ))}
        </div>
      )}
    </header>
  );
};

export default Navbar;
