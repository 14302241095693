// src/pages/TermsOfService.js

import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TermsOfService = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-lg p-8 mb-8">
          <h1 className="text-4xl font-bold">Términos de Servicio</h1>
          <p className="text-lg mt-4">
            Aquí se detallan los términos y condiciones bajo los cuales se proporciona el acceso a nuestros documentos y servicios. Asegúrate de leerlos detenidamente para entender tus derechos y obligaciones.
          </p>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TermsOfService;
