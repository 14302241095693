// src/components/Footer.js

import React from 'react';
import { Church } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h3 className="text-md font-semibold mb-2 border-b">Diosidencias - HOLY NAME OF JESUS PARISH</h3>
            <p className="text-sm">WEST HAZLETON - DIÓCESIS DE SCRANTON – PENNSYLVANIA.</p>
            <p className="text-sm">Proporcionando acceso fácil a documentos PDF desde 2024.</p>
            <p className="text-sm">Parroquia.co.</p>
          </div>
          {/* <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h4 className="text-md font-semibold mb-2 border-b">Enlaces Rápidos</h4>
            <ul className="text-sm">
              <li><a href="/privacy-policy" className="hover:text-blue-300">Política de Privacidad</a></li>
              <li><a href="/terms-of-service" className="hover:text-blue-300">Términos de Servicio</a></li>
              <li><a href="/contact" className="hover:text-blue-300">Contacto</a></li>
            </ul>
          </div> */}
          <div className="w-full md:w-1/3 flex justify-end">
            <a href="#" className="text-white hover:text-blue-300">
              <Church size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
