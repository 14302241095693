import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FileDown, Church, Search } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { FaWhatsapp, FaTwitter, FaEnvelope } from 'react-icons/fa'; // Importar iconos necesarios
import HeroSection from '../components/HeroSection';

// Componente LazyImage para cargar imágenes perezosamente
const LazyImage = ({ src, alt, ...props }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  return (
    <div ref={ref}>
      {inView ? (
        <img src={src} alt={alt} {...props} />
      ) : (
        <div className="w-full h-56 bg-gray-200 animate-pulse" />
      )}
    </div>
  );
};

// Componente SearchBar para la barra de búsqueda
const SearchBar = React.memo(({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  return (
    <form onSubmit={handleSearch} className="mb-6">
      <div className="relative">
        <input
          type="text"
          placeholder="Buscar ediciones..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        />
        <Search className="absolute top-2 left-3 text-gray-400" size={20} />
      </div>
    </form>
  );
});

// Funciones de plataformas para compartir archivos
const platforms = {
  whatsapp: (text, url) => `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`,
  twitter: (text, url) => `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`,
  email: (subject, body) => `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
};

// Componente PDFItem para mostrar cada archivo PDF
const PDFItem = React.memo(({ file, isNew }) => {
  const [showOptions, setShowOptions] = useState(false);

  const shareFile = useCallback((platform) => {
    const shareText = `Mira esta edición de Diosidencias: "${file.name}" (Publicado: ${file.data})`;
    const shareUrl = `${window.location.origin}${file.url}`;

    if (navigator.share) {
      navigator.share({
        title: file.name,
        text: shareText,
        url: shareUrl,
      }).catch((error) => {
        console.error('Error al compartir:', error);
        alert('No se pudo compartir el archivo.');
      });
    } else {
      const sharePlatformUrl = platforms[platform](shareText, shareUrl);
      window.open(sharePlatformUrl, '_blank');
    }
  }, [file]);

  return (
    <li className={`flex flex-col bg-white rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow duration-300 relative ${isNew ? 'ring-4 ring-green-300' : ''}`}>
      <LazyImage
        src={file.image}
        alt={`Portada de ${file.name}`}
        className="w-full h-56 object-top object-cover rounded-t-lg"
      />
      <div className="flex flex-col p-6">
        <div className='flex flex-row items-center justify-between h-10 mb-2 border border-gray-300 rounded-lg'>
          <div className="text-sm text-gray-500 py-1 px-4">Fecha: {file.data}</div>
          {isNew && (
            <div className="bg-red-500 text-white text-sm font-semibold h-10 px-4 w-max rounded-r-lg py-3" aria-label="Nueva edición">Nuevo</div>
          )}
        </div>
        <h3 className="text-lg font-bold text-gray-900">{file.name}</h3>
        <div className="mt-4 flex space-x-2">
          {/* Botón de compartir en vista móvil */}
          <div className="block">
            <button
              onClick={() => setShowOptions(!showOptions)}
              className="flex items-center justify-center bg-gray-50 p-2 text-gray-800 rounded-lg hover:bg-gray-100 transition-transform duration-300 transform hover:scale-105"
              aria-label="Compartir"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 12h16M4 6h16M4 18h16"
                />
              </svg>
            </button>

            {/* Menú de opciones de compartir que se despliega al hacer clic */}
            {showOptions && (
              <div className="absolute z-10 mt-2 bg-white border rounded-lg shadow-lg w-48">
                <div className='py-1 text-center border-b bg-slate-100'>Compartir</div>
                {Object.keys(platforms).map(platform => (
                  <button
                    key={platform}
                    onClick={() => {
                      shareFile(platform);
                      setShowOptions(false); // Oculta el menú después de compartir
                    }}
                    className="w-full flex items-center justify-start p-2 hover:bg-gray-100 text-gray-800 transition-colors duration-200"
                    aria-label={`Compartir en ${platform}`}
                  >
                    {platform === 'whatsapp' && (
                      <>
                        <FaWhatsapp size={20} className="mr-2" />
                        WhatsApp
                      </>
                    )}
                    {platform === 'twitter' && (
                      <>
                        <FaTwitter size={20} className="mr-2" />
                        Twitter
                      </>
                    )}
                    {platform === 'email' && (
                      <>
                        <FaEnvelope size={20} className="mr-2" />
                        Correo
                      </>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
          <a
            href={file.url}
            download
            className="flex-1 flex items-center justify-center bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 transition-transform duration-300 transform hover:scale-105"
            aria-label={`Descargar ${file.name}`}
          >
            <FileDown className="mr-2" size={20} aria-hidden="true" />
            Descargar
          </a>
        </div>
      </div>
    </li>
  );
});


// Componente principal Home
const Home = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasNewItems, setHasNewItems] = useState(false);
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const [notificationShown, setNotificationShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkForNewItems = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/data/pdf/infopdf.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      setPdfFiles(data);
      
      if (data.length > 0) {
        const newLastAddedItem = data[data.length - 1];
        setLastAddedItem((prevLastAddedItem) => {
          const isNewItem = !prevLastAddedItem || newLastAddedItem.name !== prevLastAddedItem.name;
          setHasNewItems(isNewItem);
          setNotificationShown(isNewItem);
          return newLastAddedItem;
        });
      } else {
        setHasNewItems(false);
        setNotificationShown(false);
      }
    } catch (error) {
      console.error('Error al cargar los archivos PDF:', error);
      setError('Hubo un problema al cargar los archivos. Por favor, intenta de nuevo más tarde.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkForNewItems();
    const intervalId = setInterval(checkForNewItems, 60000); // Comprobar cada minuto
    return () => clearInterval(intervalId);
  }, [checkForNewItems]);

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
  }, []);

  // Lista de archivos PDF ordenados
  const sortedPdfFiles = useMemo(() => {
    return [...pdfFiles].reverse();
  }, [pdfFiles]);

  // Filtrado de archivos PDF
  const filteredPdfFiles = useMemo(() => {
    return sortedPdfFiles.filter(file => 
      file.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedPdfFiles, searchTerm]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8">
        {/* Sección Hero */}
        <HeroSection />
        {/* Notificación de nuevos elementos */}
        {hasNewItems && notificationShown && lastAddedItem && (
          <div className="bg-yellow-500 text-white p-4 rounded-lg mb-6 text-center shadow-md">
            Nueva edición disponible: <strong>{lastAddedItem.name}</strong> (Publicado: {lastAddedItem.data})
          </div>
        )}

        {/* Barra de búsqueda */}
        <SearchBar onSearch={handleSearch} />

        {/* Contenido principal */}
        {isLoading ? (
          <p>Cargando...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredPdfFiles.length > 0 ? (
              filteredPdfFiles.map((file) => (
                <PDFItem key={file.url} file={file} isNew={lastAddedItem && lastAddedItem.name === file.name} />
              ))
            ) : (
              <li>No se encontraron resultados para tu búsqueda.</li>
            )}
          </ul>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default Home;
