import { Church } from 'lucide-react';

const HeroSection = () => (
  <div className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg shadow-lg p-12 mb-8 relative overflow-hidden">
    <div className="absolute inset-0 bg-opacity-20 bg-white mix-blend-multiply"></div>
    <div className="relative z-10 flex items-center justify-between">
      <div className='space-y-4'>
        <h1 className="text-5xl font-extrabold tracking-tight border-b-4 border-indigo-300">Diosidencias</h1>
        <h2 className="text-3xl">HOLY NAME OF JESUS PARISH</h2>
        <p className="text-lg font-light">WEST HAZLETON - DIÓCESIS DE SCRANTON – PENNSYLVANIA</p>
        <p className="text-md">Accede a nuestra colección de ediciones PDF descargables</p>
      </div>
      <Church size={140} className="hidden md:block shadow-lg border-white border-opacity-50 p-2" />
    </div>
  </div>
);

export default HeroSection;
