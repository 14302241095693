// src/pages/About.js

import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const About = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-lg p-8 mb-8">
          <h1 className="text-4xl font-bold">Sobre Nosotros</h1>
          <p className="text-lg mt-4">
            En la Parroquia del Santo Nombre de Jesús, nos dedicamos a proporcionar acceso a una vasta colección de documentos y recursos que enriquecen nuestra comunidad. Nuestro compromiso es ofrecer un servicio de calidad y facilitar el acceso a las Diosidencias de nuestra biblioteca.
          </p>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default About;
